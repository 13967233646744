
@import '../../global-styles/_variables.scss';

.Announcement {
    position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.85);
      font-family: "avantgarde-bold";
      color: $primary-color;
      font-size: 4rem;
      text-transform: uppercase;
      opacity: 0;
      transition: opacity 1s ease-in-out;
      &.visible {
        opacity: 1;
      }
      &.desktop {
              background-color: rgba(0, 0, 0, 0.5);
      }
}