@import '../../global-styles/_variables.scss';

.Textblock {
    width: 100%;
    text-align: left;
    color: red;
    margin-top: $paragraph-font-size;
    line-height: $paragraph-font-size;
    font-size: $paragraph-font-size;
    color: $primary-color;
    &.desktop {
        width: auto;
        margin-top: 0;
        .link-container {
          justify-content: flex-end;
        }
        .icons {
          height: 35px;
        }
    }
    &.mobile {
        transition: all $nav-speed $nav-ease;
        transform: translateX(0);
        &.open {
            transform: translateX(150vw);
        }
        .link-container {
          justify-content: flex-start;
        }
    }
    .link-container {
      margin: .5em 0;
      display: flex;

    }
    .icons {
      position: relative;
      width: auto;
      height: 40px;
      margin-right: .5em;
      fill: $primary-color;
      stroke-width: 1px;
    }
    a {
      display: block;
    }
}