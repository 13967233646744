$primary-color: #CEFF00;
$margin-color: $primary-color;
$content-max_width: 1440px;
$content-padding: 2vh 2.5vw;
$content-padding__desktop: 3vh 2.5vw;
$content-padding__mobile: 1.5vh 3vw;
$paragraph-font-size: 1.5rem;
$nav-speed: 0.3s;
$nav-ease: ease-out;
$ticker-speed: 70s;
$f00: 0.25s;