@import '../../global-styles/_variables.scss';


.Nav {
  position: fixed;
  top: 0;
  transform: translateX(-150%);
  left: 0%;
  width: 100%;
  height: 100%;
  transition: all $nav-speed $nav-ease;
  font-family: "Poppins";
  user-select: none;
  opacity: 0;

  a {
    font-weight: 800;
    text-decoration: none;
  }

  &.open {
    transform: translateX(0);
    opacity: 1;
  }

  ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 120px 3vw;
    color: $primary-color;
    font-size: 4.75rem;
    line-height: 4rem;
    color: $primary-color;
    text-decoration: none;
    text-transform: uppercase;
  }

  a {
    line-height: 4rem;
  }

  &.desktop {
    width: 50%;
    left: 0;
    height: auto;
    background-color: transparent;

    ul {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 120px 2vw;
      color: $primary-color;
    }

    a {
      font-size: 8.75rem;
      line-height: 7.5rem;

    }

    &__button {
      display: none;
    }

    &__menu {
      position: static;
      text-align: left;

      ul {
        li {
          margin-bottom: 0.5rem;
        }
      }
    }
  }
}


@media only screen and (min-height: 1080px) {
  .Nav.desktop ul {
    margin-top: 180px;
    // a little patch for ultra-wide screens
  }
}