@import url("https://use.typekit.net/bzz3ybv.css");
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700;800;900&display=swap');

@font-face {
    font-family: "avantgarde-regular";
    src: local("avantgarde-regular"),
        url("../fonts/Avantgarde/avantgarde-regular.TTF") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "avantgarde-bold";
    src: local("avantgarde-bold"),
        url("../fonts/Avantgarde/avantgarde-bold.TTF") format("truetype");
    font-weight: bold;
}

@font-face {
    font-family: "nimbus-regular";
    src: local("nimbus-regular"),
        url("../fonts/nimbus-sans/nimbus-regular.otf") format("otf");
    font-weight: normal;
}

@font-face {
    font-family: "nimbus-bold";
    src: local("nimbus-bold"),
        url("../fonts/nimbus-sans/nimbus-bold.otf") format("otf");
    font-weight: bold;
}