@import '../global-styles/_variables.scss';

.Screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;

    background-color: $margin-color;
    &.black {
        opacity: 0;
      }
    
      &.fade-in {
        opacity: 1;
        transition: opacity 1s ease-out;
      }
}

.Screen__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    height: 100vh;
    width: 100vw;

    background-size: cover;
    padding: $content-padding__mobile;
    background-color: black;
    
    &.desktop {
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        padding: $content-padding__desktop;
    }
}

.menu-button-svg {
    z-index: 3;
}

.open-button-svg {
    z-index: 2;
    transition: all $nav-speed $nav-ease;
    &.open {
        transform:rotate(43deg);
        #open-button {
            // transform:rotate(90deg);
        }
    }
}

svg, path {
    // color: $primary-color;
}