@import '../../global-styles/_variables.scss';

.Ticker {
  position: absolute;
  bottom: 20px;
  left: 0;
  width: 100%;
  height: auto;
  overflow: hidden;
  font-family: "Poppins";
  font-weight: 600;
  color: $primary-color;
  text-transform: capitalize;
  a {
    color: inherit;
    text-decoration: none;
      font-weight: 600;

  }
}

@media only screen and (orientation: landscape) {
  .Ticker.mobile {
    display: none;
  }
}

.Ticker__content {
  display: inline-block;
  white-space: nowrap;
  animation: ticker $ticker-speed linear infinite;
  height: 100%;
  font-size: 1.5rem;
  animation-play-state: paused;
  &.loaded {
    animation-play-state: running;
  }
  &.paused {
    animation-play-state: paused;
  }
  &.desktop {
    font-size: 4rem;
  }
}

.Ticker__content span {
  padding-right: 20px;
}

@keyframes ticker {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}