@use './global-styles/_variables.scss';
@use './global-styles/_reset.scss';
@use './global-styles/_fonts.scss';

.App {
  text-align: center;
  width: 100vw;
  height: 100%;
  overflow: hidden;
  background-color: black;
  &.mobile {
    position: absolute;
  }
}

p,a {
  font-family: "Poppins";
  font-size: 1.1em;
  line-height: 1.15em;
  font-weight: 500;
  color: #CEFF00;

}

// body{font-size:100%;}
// i{font-size: 2em;}


// @media screen and (min-width: 500px) {
//     body{font-size: 150%;}
// }

// @media screen and (min-width: 700px) {
//     body{font-size: 200%;}
// }

@media only screen and (orientation: landscape) {
  .App {
    &.mobile {
      min-height: 500px;
      .Screen {
        height: 100%;
        justify-content: flex-start;
      }
    }
  }
}